@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.style_modal__1rFdD {
  opacity: 1;
  position: absolute;
  width: 60%;
  height: 95%;
  margin-left: 20%;
  margin-right: 20%;
  top: 2%;
  bottom: 1%;
  z-index: 1100;
  background: #f9fbff;
  border: none;
  border-radius: 2%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  overflow-y: scroll;
  overflow-style: unset;
  -ms-overflow-style: none;
}

.style_modal__1rFdD::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.style_close_button__1TPWZ {
  border: 1px solid crimson;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 30px;
  top: 20px;
  transition: transform 800ms;
}

.style_close_button__1TPWZ:before {
  transform: rotate(45deg);
}

.style_close_button__1TPWZ:hover {
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  transition: transform 800ms;
}

.style_image_container__1vAy1 {
  -webkit-margin-before: 1.5em;
          margin-block-start: 1.5em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  float: left;
  width: 100%;
  height: 200px;
}

.style_recipe_image__1Y5-d {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  object-fit: cover;
  border-radius: 1%;
}

.style_header_wrapper__2Wq67 {
  -webkit-padding-before: 2em;
          padding-block-start: 2em;
  -webkit-padding-after: 2em;
          padding-block-end: 2em;
  display: flex;
  justify-content: center;
}

.style_header__1hs9t {
  display: flex;
  max-width: 80%;
  text-align: center;
}

.style_divider__2P3ZX {
  color: crimson;
}

@media only screen and (max-width: 500px) {
  .style_modal__1rFdD {
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background: #f9fbff;
    border: none;
    border-radius: 2%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    overflow-y: scroll;
    overflow-style: unset;
    -ms-overflow-style: none;
    margin-left: unset;
    margin-top: unset;
    top: unset;
    bottom: unset;
  }

  .style_header__1hs9t {
    max-width: 72%;
  }

  .style_close_button__1TPWZ {
    border: 0px solid crimson;
    border-radius: 50%;
    cursor: none;
    outline: none;
  }

  .style_close_button__1TPWZ.style_svg__1Rj_8 {
    display: none;
  }
}

.style_thumbnail__1CKiW{transition:transform .5s;height:200px;margin:0 auto}.style_thumbnail__1CKiW:hover{cursor:pointer;transform:scale(1.5)}.style_modal_overlay__1z_pR{background:rgba(0,0,0,.5);bottom:0;left:0;overflow:auto;position:fixed;right:0;top:0;z-index:900;transition:opacity 200ms linear}.style_display__3Fa8T{transition:opacity 1s,visibility 1s;opacity:1;visibility:visible}.style_hide__3YUfJ{transition:opacity 1s,visibility 1s;opacity:0;visibility:hidden}
.MuiPaper-root{width:100%;width:-webkit-fill-available;width:fill-available}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

