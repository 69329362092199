.modal {
  opacity: 1;
  position: absolute;
  width: 60%;
  height: 95%;
  margin-left: 20%;
  margin-right: 20%;
  top: 2%;
  bottom: 1%;
  z-index: 1100;
  background: #f9fbff;
  border: none;
  border-radius: 2%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  overflow-y: scroll;
  overflow-style: unset;
  -ms-overflow-style: none;
}

.modal::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.close_button {
  border: 1px solid crimson;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 30px;
  top: 20px;
  transition: transform 800ms;
}

.close_button:before {
  transform: rotate(45deg);
}

.close_button:hover {
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  transition: transform 800ms;
}

.image_container {
  margin-block-start: 1.5em;
  margin-block-end: 1em;
  float: left;
  width: 100%;
  height: 200px;
}

.recipe_image {
  width: fit-content;
  height: 100%;
  object-fit: cover;
  border-radius: 1%;
}

.header_wrapper {
  padding-block-start: 2em;
  padding-block-end: 2em;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  max-width: 80%;
  text-align: center;
}

.divider {
  color: crimson;
}

@media only screen and (max-width: 500px) {
  .modal {
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1100;
    background: #f9fbff;
    border: none;
    border-radius: 2%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    overflow-y: scroll;
    overflow-style: unset;
    -ms-overflow-style: none;
    margin-left: unset;
    margin-top: unset;
    top: unset;
    bottom: unset;
  }

  .header {
    max-width: 72%;
  }

  .close_button {
    border: 0px solid crimson;
    border-radius: 50%;
    cursor: none;
    outline: none;
  }

  .close_button.svg {
    display: none;
  }
}
